import { PlayerState, FuelAmounts, FuelType } from "./messages";
import { RenderTarget } from "./RenderTarget";
import { drawPlant } from "./cardRenderer";
import { ClickZone } from "./ClickZone";
import { Asset } from "./Assets";

export const fuelColours = {
  "coal": "#FFC040",
  "oil": "#FFFFFF",
  "gas": "#0080F0",
  "nuclear": "#FFFF00",
};
const amountNames: (keyof FuelAmounts)[] = [
  "coalAmount",
  "oilAmount",
  "gasAmount",
  "nuclearAmount",
];
const fuelTypes: FuelType[] = [
  "coal",
  "oil",
  "gas",
  "nuclear",
];

export function drawPlayer(
  target: RenderTarget,
  player: PlayerState,
  playerHue: number,
  isSelf: boolean,
  extraPlayerText: string | undefined,
  selectPlayerPlant: ((playerPlantLocation: number) => void) | undefined,
  isPlayerPlantSelected: ((playerPlantLocation: number) => boolean) | undefined,
  x: number,
  y: number,
  width: number,
  height: number): Array<ClickZone> {

  const clickZones: ClickZone[] = [];
  const fontSize = height * 0.075;
  const margin = height * 0.075;
  const cardGap = 0.2;
  const cardSize = Math.min(height * 0.5, width / (cardGap * 4 + 3));

  if (extraPlayerText) {
    target.context.strokeStyle = "#ffff00";
  } else {
    target.context.strokeStyle = "#ffffff";
  }
  target.context.strokeRect(x, y, width, height);

  target.drawIdenticon(player.uuid, playerHue, x + margin, y + margin * 0.9, fontSize);

  target.context.font = `${fontSize}px Arial`;
  target.context.textBaseline = "top";
  target.context.fillStyle = "#ffffff";
  target.context.textAlign = "left";
  target.context.fillText(player.name + (isSelf ? " (you)" : "") + (extraPlayerText ? ` (${extraPlayerText})` : ""), x + margin + fontSize, y + margin);
  target.context.font = `${fontSize}px Arial`;
  target.context.textAlign = "right";
  target.context.fillText(`€${player.money}`, x + width - margin, y + margin);
  for (let i = 0; i < 4; i++) {
    drawPlayerFuel(target, fuelTypes[i], player.resources[amountNames[i]], fontSize * 2, x + (width - margin) / 4 * i + margin, y + height - margin - fontSize * 2);
  }
  for (let i = 0; i < player.plants.length; i++) {
    const cardX = x + width / 2 + (cardSize + cardSize * cardGap) * (i - 1) - cardSize / 2;
    const cardY = y + height / 2 - cardSize / 2 - fontSize / 2;
    drawPlant(target, player.plants[i], isPlayerPlantSelected ? isPlayerPlantSelected(i) : false, false, false, cardSize, cardX, cardY);
    if (selectPlayerPlant) {
      clickZones.push({
        top: cardY,
        bottom: cardY + cardSize,
        left: cardX,
        right: cardX + cardSize,
        action: (): void => {
          selectPlayerPlant(i);
        },
      });
    }
  }

  return clickZones;
}

export function drawPlayerFuel(target: RenderTarget, fuelType: FuelType, amount: number, height: number, left: number, top: number): void {
  const fuelSize = height * 0.7;
  const fontSize = height * 0.8;
  const textOffsetX = height * 0.1;
  const textOffsetY = height * 0.2;

  const fuelOffset = (height - fuelSize) / 2;

  target.drawAsset((fuelType + "Symbol") as Asset, left + fuelOffset, top + fuelOffset, fuelSize, fuelSize);

  target.context.fillStyle = fuelColours[fuelType];
  target.context.font = `${fontSize}px Arial`;
  target.context.textBaseline = "top";
  target.context.textAlign = "left";
  target.context.fillText(`${amount}`, left + height + textOffsetX, top + textOffsetY);
}
