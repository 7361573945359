import { Connection } from "./Connection";
import { ClickZone } from "./ClickZone";
import { ServerMessage } from "./messages";
import { RenderTarget } from "./RenderTarget";
import { newUUID } from "./uuid";
import { Game } from "./Game";
import { Assets } from "./Assets";
import { woop } from "./sounds";

function getQuerryParameters(): Record<string, string | true> {
  let queryParameters: Record<string, string | true> = {};
  for (const assignment of window.location.search.substring(1).split("&")) {
    const [key, value] = assignment.split("=");
    if (value) {
      queryParameters[key] = value;
    } else {
      queryParameters[key] = true;
    }
  }
  return queryParameters;
}

function handleClick(event: MouseEvent, clickZones: ClickZone[] | undefined, requestDraw: () => void): void {
  const x = event.clientX;
  const y = event.clientY;
  for (const zone of clickZones ?? []) {
    if (x >= zone.left && x <= zone.right && y >= zone.top && y <= zone.bottom) {
      zone.action();
      requestDraw();
    }
  }
}

function init(): void {
  let nextAnimationFrame: undefined | number;
  let clickZones: undefined | Array<ClickZone>;

  function draw(): void {
    nextAnimationFrame = undefined;

    target.resize();
    if (game) {
      clickZones = game.draw(target);
    }
  }

  function requestDraw(): void {
    if (!nextAnimationFrame) {
      nextAnimationFrame = window.requestAnimationFrame(draw);
    }
  }

  const assets = new Assets(requestDraw);
  const canvas = document.getElementById("mainCanvas") as HTMLCanvasElement;
  const target = new RenderTarget(canvas, assets);

  const localID = localStorage.getItem("localID") || newUUID();
  localStorage.setItem("localID", localID);
  let playerName = localStorage.getItem("playerName") || "Player";
  if (playerName === "Player") {
    playerName = window.prompt("Enter player name:", "Player") ?? "Player";
  }
  localStorage.setItem("playerName", playerName);

  let game: Game | undefined;

  const queryParameters = getQuerryParameters();
  const secureUrl = window.location.protocol === "https:";
  const socket = new Connection(`${secureUrl ? "wss:" : "ws:"}//${window.location.host}/ws`, (): void => {
    game = new Game(localID, socket);
    const gameId = queryParameters.gameid ? queryParameters.gameid.toString() : undefined;
    game.join(gameId, playerName);
    requestDraw();
  }, (message: ServerMessage): void => {
    if (message.type === "game-state" && !queryParameters.gameid) {
      window.history.pushState("", "Gamestate", "?gameid=" + message.gameUuid);
    }
    console.debug("Recieved message:", message);
    if (game) {
      game.processMessage(message);
    }
    requestDraw();
  });

  requestDraw();
  window.onresize = requestDraw;

  canvas.addEventListener("click", (event: MouseEvent): void => handleClick(event, clickZones, requestDraw));

  const loadingTag = document.getElementById("hideOnLoad");
  if (loadingTag !== null) {
    loadingTag.style.display = "none";
  }
  console.log("%cW%co%co%ct%c!", "color: red; font-size: 3em;", "color: yellow; font-size: 3em;", "color: green; font-size: 3em;", "color: blue; font-size: 3em;", "color: purple; font-size: 3em;");
  woop();
}

window.onload = init;
