import { ClickZone } from "./ClickZone";
import { RenderTarget } from "./RenderTarget";

const ChoiceCount = 4;

export class AmountSelection {
  constructor(prompt: string, minimum: number, maximum: number, action: (amount: number) => void, cleanup: () => void) {
    this._prompt = prompt;
    this._minimum = minimum;
    this._maximum = maximum;
    this._action = action;
    this._cleanup = cleanup;
    this._delta = 0;
  }
  cancel(): void {
    this._cleanup();
  }
  decreaseAmounts(): void {
    this._delta = Math.max(0, this._delta - ChoiceCount);
  }
  drawAmounts(target: RenderTarget, innerTop: number, innerLeft: number, spacer: number, innerHeight: number, buttonStart: number, buttonWidth: number): Array<ClickZone> {
    const amountButtonWidth = buttonWidth / 3;

    target.context.font = `${innerHeight}px Arial`;
    target.context.textBaseline = "top";
    target.context.fillStyle = "#ffffff";
    target.context.textAlign = "left";

    const clickZones = [];

    target.context.fillText(this._prompt, innerLeft, innerTop);

    const start = this._minimum + this._delta;
    const end = Math.min(start + ChoiceCount - 1, this._maximum);
    if (start > this._minimum) {
      clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, amountButtonWidth, innerHeight, `-${ChoiceCount}`, (): void => this.decreaseAmounts()));
    }
    for (let extra = 0; start + extra <= end; extra++) {
      const buttonLeft = buttonStart + (amountButtonWidth + spacer) * (extra + 1);
      const amount = start + extra;
      clickZones.push(target.drawButton(buttonLeft, innerTop, spacer * 0.5, amountButtonWidth, innerHeight, amount.toString(), (): void => this.selectAmount(amount)));
    }
    if (end < this._maximum) {
      const buttonLeft = buttonStart + (amountButtonWidth + spacer) * (ChoiceCount + 1);
      clickZones.push(target.drawButton(buttonLeft, innerTop, spacer * 0.5, amountButtonWidth, innerHeight, `+${ChoiceCount}`, (): void => this.increaseAmounts()));
    }

    const cancelButtonLeft = buttonStart + (amountButtonWidth + spacer) * (ChoiceCount + 2);
    clickZones.push(target.drawButton(cancelButtonLeft, innerTop, spacer * 0.5, amountButtonWidth, innerHeight, "X", (): void => this.cancel()));

    return clickZones;
  }
  increaseAmounts(): void {
    this._delta = Math.min(this._maximum - this._minimum, this._delta + ChoiceCount);
  }
  selectAmount(amount: number): void {
    if (amount >= this._minimum && amount <= this._maximum) {
      this._action(amount);
      this._cleanup();
    }
  }
  private _action: (amount: number) => void;
  private _cleanup: () => void;
  private _delta: number;
  private _minimum: number;
  private _maximum: number;
  private _prompt: string;
}
