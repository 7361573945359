export enum Asset {
  cardBackground = "cardBackground",
  coalPlant = "coalPlant",
  oilPlant = "oilPlant",
  gasPlant = "gasPlant",
  nuclearPlant = "nuclearPlant",
  freePlant = "freePlant",
  coalSymbol = "coalSymbol",
  oilSymbol = "oilSymbol",
  gasSymbol = "gasSymbol",
  nuclearSymbol = "nuclearSymbol",
}
const assetList = [...Object.values(Asset)];

export class Assets {
  missingTexture: HTMLImageElement = new Image();
  images: Record<Asset, HTMLImageElement | undefined>;
  constructor(requestDraw: () => void) {
    this.missingTexture.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgAQMAAABJtOi3AAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV8/pEUqDhYp4pChOlkQFXHUKhShQqgVWnUwufQLmjQkKS6OgmvBwY/FqoOLs64OroIg+AHi5Oik6CIl/i8ptIjx4Lgf7+497t4B/maVqWZwHFA1y8ikkkIuvyqEXhFEGAHEMCgxU58TxTQ8x9c9fHy9S/As73N/jj6lYDLAJxDPMt2wiDeIpzctnfM+cZSVJYX4nHjMoAsSP3JddvmNc8lhP8+MGtnMPHGUWCh1sdzFrGyoxFPEcUXVKN+fc1nhvMVZrdZZ+578hZGCtrLMdZrDSGERSxAhQEYdFVRhIUGrRoqJDO0nPfxDjl8kl0yuChg5FlCDCsnxg//B727N4uSEmxRJAj0vtv0xAoR2gVbDtr+Pbbt1AgSegSut4681gZlP0hsdLX4E9G8DF9cdTd4DLneA2JMuGZIjBWj6i0Xg/Yy+KQ8M3AK9a25v7X2cPgBZ6ip9AxwcAqMlyl73eHe4u7d/z7T7+wECdHJ6DIyFCQAAAAZQTFRFAAAA/wD/Nv1irwAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+QECAMbF1mWjR8AAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAFUlEQVQI12P4/5+BgYGKBFUN+/8fAO29P8FEXAQgAAAAAElFTkSuQmCC";
    this.images = {
      cardBackground: undefined,
      coalPlant: undefined,
      oilPlant: undefined,
      gasPlant: undefined,
      nuclearPlant: undefined,
      freePlant: undefined,
      coalSymbol: undefined,
      oilSymbol: undefined,
      gasSymbol: undefined,
      nuclearSymbol: undefined,
    };
    for (const assetName of assetList) {
      this.images[assetName] = this.missingTexture;
      const loadImage = new Image();
      loadImage.onload = (): void => {
        console.debug(`Asset "${assetName} loaded`);
        this.images[assetName] = loadImage;
        requestDraw();
      };
      loadImage.src = `/assets/${assetName}.png`;
    }
  }
  get(image: Asset): HTMLImageElement {
    return this.images[image] ?? this.missingTexture;
  }
}
