import { F4, E4, Db6, G4, C5, E5, C6, G5, E6, G6, C7 } from "./notes";

const audioCtx: AudioContext = new (window.AudioContext || (window as any).webkitAudioContext || (window as any).audioContext);

let nextSoundStart = 0;

function getSoundStartTime(soundDuration: number): number {
  const startTime = Math.max(audioCtx.currentTime, nextSoundStart);
  nextSoundStart = startTime + soundDuration;
  return startTime;
}

let enabled = false;

export function soundIsEnabled(): boolean {
  return enabled;
}

export function enableSound(): void {
  enabled = true;
  woop();
}

export function disableSound(): void {
  enabled = false;
}


/**
 * Play a boop sound.
 */
export function boop(): void {
  if (!enabled) {
    return;
  }

  const startTime = getSoundStartTime(0.025 + 0.05 + 0.25);
  const attackTime = startTime + 0.025;
  const sustainTime = attackTime + 0.05;
  const releaseTime = sustainTime + 0.25;

  const gainNode = audioCtx.createGain();
  gainNode.gain.linearRampToValueAtTime(0, startTime);
  gainNode.gain.linearRampToValueAtTime(1.0, attackTime);
  gainNode.gain.linearRampToValueAtTime(1.0, sustainTime);
  gainNode.gain.exponentialRampToValueAtTime(0.001, releaseTime);

  gainNode.connect(audioCtx.destination);

  const oscillator = audioCtx.createOscillator();
  oscillator.frequency.linearRampToValueAtTime(F4, startTime);
  oscillator.frequency.linearRampToValueAtTime(F4, attackTime);
  oscillator.frequency.linearRampToValueAtTime(E4, sustainTime);
  oscillator.frequency.linearRampToValueAtTime(E4, releaseTime);
  oscillator.type = "triangle";
  oscillator.connect(gainNode);

  oscillator.start(startTime);
  oscillator.stop(releaseTime);
}

/**
 * Play a ping sound.
 */
export function ping(): void {
  if (!enabled) {
    return;
  }

  const startTime = getSoundStartTime(0.025 + 0.05 + 0.75);
  const attackTime = startTime + 0.025;
  const sustainTime = attackTime + 0.05;
  const releaseTime = sustainTime + 0.75;

  const gainNode = audioCtx.createGain();
  gainNode.gain.linearRampToValueAtTime(0, startTime);
  gainNode.gain.linearRampToValueAtTime(1.0, attackTime);
  gainNode.gain.linearRampToValueAtTime(1.0, sustainTime);
  gainNode.gain.exponentialRampToValueAtTime(0.001, releaseTime);

  gainNode.connect(audioCtx.destination);

  const oscillator = audioCtx.createOscillator();
  oscillator.frequency.linearRampToValueAtTime(Db6, startTime);
  oscillator.frequency.linearRampToValueAtTime(Db6, attackTime);
  oscillator.frequency.linearRampToValueAtTime(Db6, sustainTime);
  oscillator.frequency.exponentialRampToValueAtTime(C6, releaseTime);
  oscillator.type = "triangle";
  oscillator.connect(gainNode);

  oscillator.start(startTime);
  oscillator.stop(releaseTime);
}

/**
 * Play a tada sound.
 */
export function tada(): void {
  if (!enabled) {
    return;
  }

  const startTime = getSoundStartTime(0.025 + 0.05 + 0.05 + 0.025 + 0.05 + 1.0);
  const taAttackTime = startTime + 0.025;
  const taSustainTime = taAttackTime + 0.05;
  const taReleaseTime = taSustainTime + 0.05;
  const daAttackTime = taReleaseTime + 0.025;
  const daSustainTime = daAttackTime + 0.05;
  const daReleaseTime = daSustainTime + 1.0;

  const gainNode = audioCtx.createGain();
  gainNode.gain.linearRampToValueAtTime(0, startTime);
  gainNode.gain.linearRampToValueAtTime(0.75, taAttackTime);
  gainNode.gain.linearRampToValueAtTime(0.75, taSustainTime);
  gainNode.gain.exponentialRampToValueAtTime(0.001, taReleaseTime);
  gainNode.gain.linearRampToValueAtTime(0, startTime);
  gainNode.gain.linearRampToValueAtTime(1.0, daAttackTime);
  gainNode.gain.linearRampToValueAtTime(1.0, daSustainTime);
  gainNode.gain.exponentialRampToValueAtTime(0.001, daReleaseTime);

  gainNode.connect(audioCtx.destination);

  const lowGainNode = audioCtx.createGain();
  lowGainNode.gain.value = 0.5;
  lowGainNode.connect(gainNode);

  const oscillatorE4 = audioCtx.createOscillator();
  oscillatorE4.frequency.value = E4;
  oscillatorE4.type = "sine";
  oscillatorE4.connect(lowGainNode);
  oscillatorE4.start(startTime);
  oscillatorE4.stop(daReleaseTime);

  const oscillatorG4 = audioCtx.createOscillator();
  oscillatorG4.frequency.value = G4;
  oscillatorG4.type = "sine";
  oscillatorG4.connect(lowGainNode);
  oscillatorG4.start(startTime);
  oscillatorG4.stop(daReleaseTime);

  const oscillatorC5 = audioCtx.createOscillator();
  oscillatorC5.frequency.value = C5;
  oscillatorC5.type = "sine";
  oscillatorC5.connect(lowGainNode);
  oscillatorC5.start(startTime);
  oscillatorC5.stop(daReleaseTime);

  const midGainNode = audioCtx.createGain();
  midGainNode.gain.value = 0.25;
  midGainNode.connect(gainNode);

  const oscillatorE5 = audioCtx.createOscillator();
  oscillatorE5.frequency.value = E5;
  oscillatorE5.type = "sine";
  oscillatorE5.connect(midGainNode);
  oscillatorE5.start(startTime);
  oscillatorE5.stop(daReleaseTime);

  const oscillatorG5 = audioCtx.createOscillator();
  oscillatorG5.frequency.value = G5;
  oscillatorG5.type = "sine";
  oscillatorG5.connect(midGainNode);
  oscillatorG5.start(startTime);
  oscillatorG5.stop(daReleaseTime);

  const oscillatorC6 = audioCtx.createOscillator();
  oscillatorC6.frequency.value = C6;
  oscillatorC6.type = "sine";
  oscillatorC6.connect(midGainNode);
  oscillatorC6.start(startTime);
  oscillatorC6.stop(daReleaseTime);

  const highGainNode = audioCtx.createGain();
  highGainNode.gain.value = 0.125;
  highGainNode.connect(gainNode);

  const oscillatorE6 = audioCtx.createOscillator();
  oscillatorE6.frequency.value = E6;
  oscillatorE6.type = "sine";
  oscillatorE6.connect(highGainNode);
  oscillatorE6.start(startTime);
  oscillatorE6.stop(daReleaseTime);

  const oscillatorG6 = audioCtx.createOscillator();
  oscillatorG6.frequency.value = G6;
  oscillatorG6.type = "sine";
  oscillatorG6.connect(highGainNode);
  oscillatorG6.start(startTime);
  oscillatorG6.stop(daReleaseTime);

  const oscillatorC7 = audioCtx.createOscillator();
  oscillatorC7.frequency.value = C7;
  oscillatorC7.type = "sine";
  oscillatorC7.connect(highGainNode);
  oscillatorC7.start(startTime);
  oscillatorC7.stop(daReleaseTime);
}

/**
 * Play a woop sound.
 */
export function woop(): void {
  if (!enabled) {
    return;
  }

  const startTime = getSoundStartTime(0.15 + 0.25 + 0.05 + 0.05);
  const midAttackTime = startTime + 0.15;
  const attackTime = startTime + 0.25;
  const sustainTime = attackTime + 0.05;
  const releaseTime = sustainTime + 0.05;

  const gainNode = audioCtx.createGain();
  gainNode.gain.linearRampToValueAtTime(0, startTime);
  gainNode.gain.linearRampToValueAtTime(0.5, midAttackTime);
  gainNode.gain.linearRampToValueAtTime(0.75, attackTime);
  gainNode.gain.linearRampToValueAtTime(1.0, sustainTime);
  gainNode.gain.exponentialRampToValueAtTime(0.001, releaseTime);

  gainNode.connect(audioCtx.destination);

  const oscillator = audioCtx.createOscillator();
  oscillator.frequency.linearRampToValueAtTime(E4, startTime);
  oscillator.frequency.linearRampToValueAtTime(G4, midAttackTime);
  oscillator.frequency.linearRampToValueAtTime(E5, attackTime);
  oscillator.type = "triangle";
  oscillator.connect(gainNode);

  oscillator.start(startTime);
  oscillator.stop(releaseTime);
}
