import { drawIcon } from "jdenticon";

import { Assets, Asset } from "./Assets";
import { ClickZone } from "./ClickZone";
import { Vec2 } from "./Vec2";

export class RenderTarget {
  assets: Assets;
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  size: Vec2;
  constructor(canvas: HTMLCanvasElement, assets: Assets) {
    this.assets = assets;
    this.canvas = canvas;
    const nullableContext = canvas.getContext("2d");
    if (nullableContext === null) {
      throw new Error("Your browser is not supported.");
    }
    this.context = nullableContext;
    this.size = new Vec2(0, 0);
  }
  drawAsset(assetName: Asset, posX: number, posY: number, width: number, height: number): void {
    const asset = this.assets.get(assetName);
    const ratio = asset.width / asset.height;
    const actualWidth = Math.min(width, height * ratio);
    const actualHeight = Math.min(height, width / ratio);
    const xAdjustment = (width - actualWidth) / 2;
    const yAdjustment = (height - actualHeight) / 2;
    this.context.drawImage(this.assets.get(assetName), posX + xAdjustment, posY + yAdjustment, actualWidth, actualHeight);
  }
  drawIdenticon(uuid: string, hue: number, posX: number, posY: number, size: number): void {
    this.context.save();
    this.context.translate(posX, posY);
    drawIcon(this.context, uuid, size, {
      hues: [ hue ],
      lightness: {
        color: [0.50, 0.50],
        grayscale: [0.75, 0.75],
      },
      saturation: {
        color: 1.00,
        grayscale: 0.75,
      },
    });
    this.context.restore();
  }
  drawButton(posX: number, posY: number, spacer: number, width: number, height: number, text: string, action: () => void): ClickZone {
    this.context.strokeStyle = "#ffffff";
    this.context.strokeRect(posX, posY, width, height);
    const textHeight = height - spacer * 2;
    this.context.font = `${textHeight}px Arial`;
    this.context.textBaseline = "top";
    this.context.fillStyle = "#ffffff";
    this.context.textAlign = "center";
    this.context.fillText(text, posX + width / 2, posY + spacer);
    return {
      bottom: posY + height,
      left: posX,
      right: posX + width,
      top: posY,
      action,
    };
  }
  resize(): void {
    this.size.x = this.canvas.offsetWidth;
    this.size.y = this.canvas.offsetHeight;
    this.canvas.width = this.size.x;
    this.canvas.height = this.size.y;
  }
}
