import { ClickZone } from "./ClickZone";
import { RenderTarget } from "./RenderTarget";

export class Confirmation {
  constructor(prompt: string, confirmText: string, showCancel: boolean, action: () => boolean, cleanup: () => void) {
    this._prompt = prompt;
    this._confirmText = confirmText;
    this._showCancel = showCancel;
    this._action = action;
    this._cleanup = cleanup;
  }
  cancel(): void {
    this._cleanup();
  }
  drawConfirmation(target: RenderTarget, innerTop: number, innerLeft: number, spacer: number, innerHeight: number, buttonStart: number, buttonWidth: number): Array<ClickZone> {
    target.context.font = `${innerHeight}px Arial`;
    target.context.textBaseline = "top";
    target.context.fillStyle = "#ffffff";
    target.context.textAlign = "left";

    const clickZones = [];

    target.context.fillText(this._prompt, innerLeft, innerTop);

    clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, buttonWidth, innerHeight, this._confirmText, (): void => this.selectConfirm()));
    if (this._showCancel) {
      clickZones.push(target.drawButton(buttonStart + buttonWidth + spacer, innerTop, spacer * 0.5, buttonWidth, innerHeight, "Cancel", (): void => this.cancel()));
    }

    return clickZones;
  }
  selectConfirm(): void {
    if (this._action()) {
      this._cleanup();
    }
  }
  private _action: () => boolean;
  private _cleanup: () => void;
  private _confirmText: string;
  private _prompt: string;
  private _showCancel: boolean;
}
