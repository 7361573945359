import { ServerMessage, ClientMessage } from "./messages";

export class Connection {
  constructor(url: string, onReady: () => void, onMessage: (message: ServerMessage) => void) {
    this._url = url;
    this._onReady = onReady;
    this._onMessage = onMessage;
    this._createSocket();
  }
  send(message: ClientMessage): void {
    this._socket.send(JSON.stringify(message));
  }
  private _createSocket(): void {
    this._socket = new WebSocket(this._url);
    this._socket.onopen = (): void => {
      this._reconnectDelay = 100;
      this._onReady();
    };
    this._socket.onmessage = (message: MessageEvent): void => {
      this._onMessage(JSON.parse(message.data) as ServerMessage);
    };
    this._socket.onclose = (): void => {
      console.warn("Disconnected; trying to reconnect...");
      setTimeout((): void => this._createSocket(), this._reconnectDelay);
      this._reconnectDelay *= 2;
    };
  }
  private readonly _onReady: () => void;
  private readonly _onMessage: (message: ServerMessage) => void;
  private _reconnectDelay = 100;
  private _socket!: WebSocket;
  private readonly _url: string;
}
