import { PlantCard, FuelCard } from "./messages";
import { RenderTarget } from "./RenderTarget";
import { Asset } from "./Assets";

const colours = {
  "coal": "#402000",
  "oil": "#000000",
  "gas": "#0080F0",
  "nuclear": "#FFFF00",
  "free": "#00FF00",
};

export enum DeckType {
  PlantDark,
  PlantLight,
  Resource,
  ResourcePlus,
  ResourcePlusPlus,
}

export function drawDeck(target: RenderTarget, deckType: DeckType, deckCount: number, tileSize: number, cardX: number, cardY: number): void {
  target.drawAsset(Asset.cardBackground, cardX, cardY, tileSize, tileSize);

  // TODO deck back assets!
  // target.drawAsset((fuel.fuelType + "Symbol") as Asset, cardX + fuelOffset, cardY + fuelOffset, fuelSize, fuelSize);

    const gap = tileSize * 0.1;

    const topWord = deckType === DeckType.PlantDark ? "Dark"
      : deckType === DeckType.PlantLight ? "Light"
      : deckType === DeckType.Resource ? "Resource"
      : "";
    const middleWord = deckType === DeckType.PlantDark || deckType === DeckType.PlantLight ? "Plant"
      : deckType === DeckType.Resource ? "Deck"
      : deckType === DeckType.ResourcePlus ? "+"
      : "++";

    target.context.font = `${tileSize * 0.2}px Arial`;
    target.context.textBaseline = "top";
    target.context.textAlign = "center";
    target.context.fillStyle = "#000000";
    target.context.fillText(topWord, cardX + tileSize / 2, cardY + gap);
    target.context.textBaseline = "middle";
    target.context.textAlign = "center";
    target.context.fillText(middleWord, cardX + tileSize / 2, cardY + tileSize / 2);

    target.context.font = `${tileSize * 0.15}px Arial`;
    target.context.textBaseline = "bottom";
    target.context.textAlign = "center";
    target.context.fillText(`(${deckCount})`, cardX + tileSize / 2, cardY + tileSize - gap);
}

export function drawPlant(target: RenderTarget, plant: PlantCard, isSelected: boolean, isDiscounted: boolean, isFaded: boolean, tileSize: number, cardX: number, cardY: number): void {
  if (isSelected) {
    target.context.strokeStyle = "#ffff00";
    target.context.strokeRect(cardX - 2, cardY - 2, tileSize + 4, tileSize + 4);
  }

  target.drawAsset(Asset.cardBackground, cardX, cardY, tileSize, tileSize);

  if (plant.plantType === "one-more-round") {
    const gap = tileSize * 0.1;

    target.context.font = `${tileSize * 0.25}px Arial`;
    target.context.textBaseline = "top";
    target.context.textAlign = "center";
    target.context.fillStyle = "#000000";
    target.context.fillText("One", cardX + tileSize / 2, cardY + gap);
    target.context.textBaseline = "middle";
    target.context.textAlign = "center";
    target.context.fillText("More", cardX + tileSize / 2, cardY + tileSize / 2);
    target.context.textBaseline = "bottom";
    target.context.textAlign = "center";
    target.context.fillText("Round", cardX + tileSize / 2, cardY + tileSize - gap);
    return;
  }

  const plantSize = tileSize * 0.4;
  const symbolSize = tileSize * 0.15;
  const symbolOffsetX = tileSize * 0.05;
  const symbolGap = symbolSize + 0.1;
  const fontSize = symbolSize * 1.5;

  const plantOffset = (tileSize - plantSize) / 2;
  const symbolOffsetY = tileSize - (symbolSize + symbolOffsetX);
  const textOffset = symbolOffsetX;
  const textOppositeOffset = tileSize - (textOffset);

  target.context.font = fontSize.toString() + "px Arial";
  const plantAsset = plant.plantType === "hybrid" ? Asset.gasPlant : (plant.plantType + "Plant") as Asset;
  const resourceAsset = plant.plantType === "hybrid" ? Asset.oilSymbol : (plant.plantType + "Symbol") as Asset;
  target.drawAsset(plantAsset, cardX + plantOffset, cardY + plantOffset, plantSize, plantSize);
  if (plant.plantType !== "free") {
    for (let i = 0; i < plant.resourcesNeeded; i++) {
      target.drawAsset(resourceAsset, cardX + symbolOffsetX + i * symbolGap, cardY + symbolOffsetY, symbolSize, symbolSize);
      if (plant.plantType === "hybrid") {
        target.drawAsset(Asset.gasSymbol, cardX + symbolOffsetX + i * symbolGap, cardY + symbolOffsetY - symbolGap / 2, symbolSize, symbolSize);
      }
    }
  }
  target.context.textBaseline = "top";
  target.context.textAlign = "left";
  target.context.fillStyle = plant.plantType === "hybrid" ? colours.oil : colours[plant.plantType];
  target.context.fillText(plant.cost.toString(), cardX + textOffset, cardY + textOffset);
  target.context.textAlign = "right";
  target.context.fillStyle = plant.plantType === "hybrid" ? colours.gas : colours[plant.plantType];
  target.context.fillText(plant.cost.toString(), cardX + textOppositeOffset, cardY + textOffset);
  target.context.textBaseline = "bottom";
  target.context.fillStyle = "#000000";
  target.context.fillText(`€${plant.power}`, cardX + textOppositeOffset, cardY + textOppositeOffset);

  if (isDiscounted) {
    target.context.font = `${tileSize * 0.8}px Arial`;
    target.context.fillStyle = "#66ff00";
    target.context.textBaseline = "middle";
    target.context.textAlign = "center";

    target.context.save();
    target.context.translate(cardX + tileSize / 2, cardY + tileSize / 2);
    target.context.rotate(Math.PI / 6);
    target.context.fillText("1", 0, 0);
    target.context.restore();
  }
  if (isFaded) {
    target.context.fillStyle = "#000000";
    target.context.globalAlpha = 0.5;
    target.context.fillRect(cardX, cardY, tileSize, tileSize);
    target.context.globalAlpha = 1.0;
  }
}

export function drawFuel(target: RenderTarget, fuel: FuelCard, isSelected: boolean, tileSize: number, cardX: number, cardY: number): void {
  if (isSelected) {
    target.context.strokeStyle = "#ffff00";
    target.context.strokeRect(cardX - 2, cardY - 2, tileSize + 4, tileSize + 4);
  }

  target.drawAsset(Asset.cardBackground, cardX, cardY, tileSize, tileSize);
  const fuelSize = tileSize * 0.4;
  const fontSize = tileSize * 0.2;
  const textOffset = tileSize * 0.05;

  const fuelOffset = (tileSize - fuelSize) / 2;

  target.drawAsset((fuel.fuelType + "Symbol") as Asset, cardX + fuelOffset, cardY + fuelOffset, fuelSize, fuelSize);

  target.context.fillStyle = colours[fuel.fuelType];
  target.context.font = fontSize.toString() + "px Arial";
  target.context.textBaseline = "top";
  target.context.textAlign = "center";
  target.context.fillText(fuel.amount.toString() + " / " + fuel.amountTotal.toString(), cardX + tileSize / 2, cardY + textOffset);
}
