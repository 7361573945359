import { Connection } from "./Connection";
import { PlayerAction, ServerMessage, Player, PlayerState, FuelAmounts, FuelStore, PlantCard, ShopFuelPosition, DeckInformation, PlayerActionWithPlayer } from "./messages";
import { RenderTarget } from "./RenderTarget";
import { drawDeck, DeckType, drawPlant, drawFuel } from "./cardRenderer";
import { ClickZone } from "./ClickZone";
import { drawPlayer } from "./playerRenderer";
import { AmountSelection } from "./AmmountSelection";
import { Confirmation } from "./Confirmation";
import { ping, boop, tada, soundIsEnabled, enableSound, disableSound } from "./sounds";

export class Game {
  constructor(playerUUID: Player, socket: Connection) {
    this._self = playerUUID;
    this._socket = socket;
    this._deck = {
      nextPowerPlantDark: true,
      powerPlantDeckCount: 1,
      resourceDeckCount: 1,
      resourcePlusUsed: false,
      resourcePlusPlusUsed: false,
    };
    this._store = {
      fuel: ([
        [undefined, undefined, undefined, undefined, undefined, undefined],
        [undefined, undefined, undefined, undefined, undefined, undefined],
        [undefined, undefined, undefined, undefined, undefined, undefined],
        [undefined, undefined, undefined, undefined, undefined, undefined],
      ]),
      plant: [],
      plantDiscountAvailable: false,
    };
    this._playerOrder = [];
    this._players = [];
    this._playerHuesByUuid = {};
    this._playersByUuid = {};
  }
  draw(target: RenderTarget): Array<ClickZone> {
    const canvasHeight = target.size.y;
    const canvasWidth = target.size.x;
    const height = canvasHeight;
    const width = Math.min(canvasWidth, canvasHeight * 2);
    const top = 0;
    const left = (canvasWidth - width) / 2;
    const spacer = Math.min(height * 0.01, width * 0.01);

    const upperTop = top + spacer;
    const upperHeight = Math.min(height * 0.05, width * 0.045);
    const upperLeft = left + spacer;
    const upperWidth = width - spacer - spacer;

    const playerOrderWidth = this.drawPlayerOrder(target, upperTop, upperLeft, spacer, upperHeight);

    const messageZoneLeft = playerOrderWidth > 0
      ? upperLeft + playerOrderWidth + spacer
      : upperLeft;
    const messageZoneWidth = playerOrderWidth > 0
      ? upperWidth - playerOrderWidth - spacer
      : upperWidth;
    const clickZonesTopArea = this.drawTopArea(target, upperTop, messageZoneLeft, spacer, messageZoneWidth, upperHeight);

    const lowerTop = top + spacer + upperHeight + spacer;
    const lowerHeight = height - spacer - upperHeight - spacer - spacer;
    const lowerLeft = left + spacer;
    const lowerWidth = width - spacer - spacer;

    const twoPlayerColumns = width / height > 0.9;
    const clickZonesMainArea = this.drawMainArea(target, twoPlayerColumns, lowerTop, lowerLeft, spacer, lowerWidth, lowerHeight);

    return clickZonesTopArea.concat(clickZonesMainArea);
  }
  join(gameId: string | undefined, playerName: string): void {
    this._socket.send({
      type: "join-game",
      gameUuid: gameId,
      player: this._self,
      playerName,
    });
  }
  drawPlayerOrder(target: RenderTarget, top: number, left: number, spacer: number, height: number): number {
    if (this._players.length === 0) {
      return 0;
    }

    let orderCharacter: string | undefined;
    let orderSpace = 0;
    let highlightPlayer: Player | undefined;
    const donePlayers: Record<Player, true> = {};
    if (this._action
      && (this._action.actionType === "bid-power-plant"
        || this._action.actionType === "counter-bid"
        || this._action.actionType === "scrap-power-plant"
        || this._action.actionType === "power-up")) {
      orderCharacter = "→";
      orderSpace = height * 0.5;
      highlightPlayer = this._action.actionType === "counter-bid" || this._action.actionType === "scrap-power-plant"
        ? this._action.biddingPlayer
        : this._action.player;
      if (this._action.actionType === "bid-power-plant"
        || this._action.actionType === "counter-bid"
        || this._action.actionType === "scrap-power-plant") {
        for (const donePlayer of this._action.doneBidding) {
          donePlayers[donePlayer] = true;
        }
      }
    } else if (this._action && this._action.actionType === "buy-fuel") {
      orderCharacter = "←";
      orderSpace = height * 0.5;
      highlightPlayer = this._action.player;
    }

    const innerTop = top + spacer;
    const innerLeft = left + spacer;
    const innerHeight = height - spacer * 2;
    const innerWidth = this._players.length * (innerHeight + spacer + orderSpace) - spacer - orderSpace;
    const actualWidth = innerWidth + (spacer) * 2;

    target.context.strokeStyle = "#ffffff";
    target.context.strokeRect(left, top, actualWidth, height);

    if (orderCharacter) {
      target.context.font = `${height * 0.7}px Arial`;
      target.context.textBaseline = "top";
      target.context.fillStyle = "#ffffff";
      target.context.textAlign = "left";
    }

    const orderTextSpacer = height * 0.6;
    let gap = 0;
    for (let i = 0; i < this._playerOrder.length; i += 1) {
      const player = this._playerOrder[i];
      const playerHue = this._playerHuesByUuid[player];
      target.drawIdenticon(player, playerHue, innerLeft + gap, innerTop, innerHeight);
      if (highlightPlayer === player) {
        target.context.strokeStyle = "#ffff00";
        target.context.strokeRect(innerLeft + gap - 2, innerTop - 2, innerHeight + 4, innerHeight + 4);
      }
      if (donePlayers[player]) {
        target.context.fillStyle = "#000000";
        target.context.globalAlpha = 0.75;
        target.context.fillRect(innerLeft + gap, innerTop, innerHeight, innerHeight);
        target.context.fillStyle = "#ffffff";
        target.context.globalAlpha = 1.0;
      }
      if (orderCharacter && i < this._playerOrder.length - 1) {
        target.context.fillText(orderCharacter, innerLeft + gap + orderTextSpacer, innerTop);
      }
      gap += innerHeight + spacer + orderSpace;
    }

    return actualWidth;
  }
  drawTopArea(target: RenderTarget, top: number, left: number, spacer: number, width: number, height: number): Array<ClickZone> {
    target.context.strokeStyle = "#ffffff";
    target.context.strokeRect(left, top, width, height);

    const clickZones = [];

    const innerTop = top + spacer;
    const innerLeft = left + spacer;
    const innerHeight = height - spacer * 2;
    const innerWidth = width - spacer * 2;
    const buttonStart = innerLeft + innerWidth / 2;
    const buttonWidth = Math.min(innerWidth / 6, innerHeight * 5);

    if (this._errorMessage) {
      target.context.font = `${innerHeight}px Arial`;
      target.context.textBaseline = "top";
      target.context.fillStyle = "#ffffff";
      target.context.textAlign = "left";
      target.context.fillText(this._errorMessage, innerLeft, innerTop);
      if (this._errorIsJoinError) {
        clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, buttonWidth, innerHeight, "New Game", Game._newGameAction));
      } else {
        clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, buttonWidth, innerHeight, "OK", (): void => this._errorMessage = undefined));
      }
    } else if (this._amountSelection) {
      clickZones.push(...this._amountSelection.drawAmounts(target, innerTop, innerLeft, spacer, innerHeight, buttonStart, buttonWidth));
    } else if (this._confirmation) {
      clickZones.push(...this._confirmation.drawConfirmation(target, innerTop, innerLeft, spacer, innerHeight, buttonStart, buttonWidth));
    } else if (this._action) {
      target.context.font = `${innerHeight}px Arial`;
      target.context.textBaseline = "top";
      target.context.fillStyle = "#ffffff";
      target.context.textAlign = "left";

      if (this._action.actionType === "join-game") {
        target.context.fillText("Waiting for more players to join...", innerLeft, innerTop);
        clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, buttonWidth, innerHeight, "Copy URL", Game._copyUrlAction));
      } else if (this._action.actionType === "join-or-start-game") {
        target.context.fillText("Start game or wait for more players?", innerLeft, innerTop);
        clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, buttonWidth, innerHeight, "Copy URL", Game._copyUrlAction));
        clickZones.push(target.drawButton(buttonStart + buttonWidth + spacer, innerTop, spacer * 0.5, buttonWidth, innerHeight, "Start Game", this._startGameAction));
      } else if (this._action.actionType === "start-game") {
        target.context.fillText("Ready to start!", innerLeft, innerTop);
        clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, buttonWidth, innerHeight, "Start Game", this._startGameAction));
      } else if (this._action.actionType === "bid-power-plant") {
        if (this._action.player === this._self) {
          target.context.fillText("Please select a plant to bid on.", innerLeft, innerTop);
          clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, buttonWidth, innerHeight, "Skip Bidding", this._skipBiddingAction));
        } else {
          target.context.fillText(`Waiting for ${this._playersByUuid[this._action.player].name} to bid on plant.`, innerLeft, innerTop);
        }
      } else if (this._action.actionType === "counter-bid") {
        if (this._action.player === this._self) {
          const currentBid = this._action.currentBid;
          const money = this._getMoney();
          if (currentBid < money) {
            target.context.fillText(`Current bid is €${currentBid}; counter bid?`, innerLeft, innerTop);
            clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, buttonWidth, innerHeight, "Counter Bid", (): void => this._counterBidAction(currentBid)));
            clickZones.push(target.drawButton(buttonStart + buttonWidth + spacer, innerTop, spacer * 0.5, buttonWidth, innerHeight, "Skip Bidding", this._skipCounterBiddingAction));
          } else {
            target.context.fillText(`Current bid is €${currentBid}; you must skip.`, innerLeft, innerTop);
            clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, buttonWidth, innerHeight, "Skip Bidding", this._skipCounterBiddingAction));
          }
        } else {
          target.context.fillText(`Waiting for ${this._playersByUuid[this._action.player].name} to counter-bid on plant.`, innerLeft, innerTop);
        }
      } else if (this._action.actionType === "scrap-power-plant") {
        if (this._action.player === this._self) {
          target.context.fillText("Please select a plant to scrap.", innerLeft, innerTop);
        } else {
          target.context.fillText(`Waiting for ${this._playersByUuid[this._action.player].name} to scrap a plant.`, innerLeft, innerTop);
        }
      } else if (this._action.actionType === "buy-fuel") {
        if (this._action.player === this._self) {
          target.context.fillText("Please select all fuel to purchase.", innerLeft, innerTop);
          clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, buttonWidth, innerHeight, "Skip Fuel", this._skipFuelAction));
        } else {
          target.context.fillText(`Waiting for ${this._playersByUuid[this._action.player].name} to buy fuel.`, innerLeft, innerTop);
        }
      } else if (this._action.actionType === "power-up") {
        if (this._action.player === this._self) {
          target.context.fillText("Please select plants to power up.", innerLeft, innerTop);
          clickZones.push(target.drawButton(buttonStart, innerTop, spacer * 0.5, buttonWidth, innerHeight, "Skip Power Up", this._skipPowerUpAction));
        } else {
          target.context.fillText(`Waiting for ${this._playersByUuid[this._action.player].name} to power up.`, innerLeft, innerTop);
        }
      } else if (this._action.actionType === "game-over") {
        target.context.fillText(`The winner of the game is ${this._playersByUuid[this._action.winner].name}!`, innerLeft, innerTop);
      }
    }

    const soundButtonWidth = buttonWidth * 0.3;
    const soundEnabled = soundIsEnabled();
    const icon = soundEnabled ? "🔈" : "🔇";
    clickZones.push(target.drawButton(innerLeft + innerWidth - soundButtonWidth, innerTop, spacer * 0.5, soundButtonWidth, innerHeight, icon, (): void => {
      if (soundEnabled) {
        disableSound();
      } else {
        enableSound();
      }
    }));

    return clickZones;
  }
  drawMainArea(target: RenderTarget, twoPlayerColumns: boolean, top: number, left: number, spacer: number, width: number, height: number): Array<ClickZone> {
    const [cardsWidth, cardsHeight, clickZonesCards] = this.drawCards(target, twoPlayerColumns, top, left, spacer, width, height);
    const clickZonesPlayers = this.drawPlayers(target, twoPlayerColumns, top, left, spacer, width, height, cardsWidth, cardsHeight);

    return clickZonesCards.concat(clickZonesPlayers);
  }
  drawCards(target: RenderTarget, twoPlayerColumns: boolean, top: number, left: number, spacer: number, width: number, height: number): [number, number, Array<ClickZone>] {
    // Cards
    const clickZones: Array<ClickZone> = [];
    const gridWidth = 5;
    const gridHeight = 9; // 2 rows for plants, 1 row for prices, 6 rows for fuel
    const maxHeight = height;
    const maxWidth = twoPlayerColumns ? width * 0.4 : width * 0.5;
    const tileSize = Math.min(maxHeight / gridHeight, maxWidth / gridWidth);
    const cardsHeight = tileSize * gridHeight;
    const cardsWidth = tileSize * gridWidth;
    const gridOriginX = twoPlayerColumns
      ? left + (width - cardsWidth) / 2
      : left;
    const gridOriginY = top;

    if (this._action && this._action.actionType === "game-over") {
      target.context.strokeStyle = "#ffffff";
      target.context.strokeRect(gridOriginX, gridOriginY, cardsWidth, cardsHeight);

      const fontSize = tileSize * 0.2;
      const headerTop = top + spacer;
      const headerTopRowTwo = headerTop + spacer + fontSize;
      const playerSize = tileSize * 0.3;
      const playerGap = spacer;

      // draw header
      target.context.font = `${fontSize}px Arial`;
      target.context.textBaseline = "top";
      target.context.textAlign = "right";
      target.context.fillText("Victory", gridOriginX + cardsWidth * 0.5, headerTop);
      target.context.fillText("Points", gridOriginX + cardsWidth * 0.5, headerTopRowTwo);

      target.context.fillText("Remaining", gridOriginX + cardsWidth * 0.75, headerTop);
      target.context.fillText("Money", gridOriginX + cardsWidth * 0.75, headerTopRowTwo);

      target.context.fillText("Biggest", gridOriginX + cardsWidth - spacer, headerTop);
      target.context.fillText("Plant", gridOriginX + cardsWidth - spacer, headerTopRowTwo);

      // draw players
      let playerTop = top + spacer + tileSize;
      for (const playerStats of this._action.endGameStats) {
        target.drawIdenticon(playerStats.player, this._playerHuesByUuid[playerStats.player], gridOriginX + spacer, playerTop, playerSize);
        const textTop = playerTop + spacer * 0.5;
        target.context.textAlign = "left";
        target.context.fillText(this._playersByUuid[playerStats.player].name, gridOriginX + spacer + (playerSize + spacer) * 1, textTop);
        target.context.textAlign = "right";
        target.context.fillText(`${playerStats.victoryPoints} VP`, gridOriginX + cardsWidth * 0.5, textTop);
        target.context.fillText(`€${playerStats.remainingMoney}`, gridOriginX + cardsWidth * 0.75, textTop);
        target.context.fillText(`${playerStats.biggestPowerPlant}`, gridOriginX + cardsWidth - spacer, textTop);
        playerTop += playerSize + playerGap;
      }
    } else if (this._store.plant.length === 0) {
      // don't draw card section before game starts
    } else {
      target.context.strokeStyle = "#ffffff";
      target.context.strokeRect(gridOriginX, gridOriginY, cardsWidth, cardsHeight);
      const cardSize = tileSize - spacer * 2;
      const cardOffset = (tileSize - cardSize) / 2;
      for (let x = 0; x < gridWidth; x++) {
        for (let y = 0; y < gridHeight; y++) {
          const cardX = gridOriginX + tileSize * x;
          const cardY = gridOriginY + tileSize * y;
          if (y < 2 && x < 4) {
            // power plants are in the first two rows and the first four columns
            const finalRound = this._store.plant.length <= 6;
            const plantPos = finalRound
              ? x - 1 + y * 3
              : x + y * 4;
            const plant = this._store.plant[plantPos];
            if (plant && (!finalRound || x > 0)) {
              const isDiscounted = plantPos === 0 && this._store.plantDiscountAvailable;
              const isPlantActive = this._getIsPlantActive(plant, plantPos);
              const isAvailable = finalRound || y === 0;
              drawPlant(target, plant, isPlantActive, isDiscounted, !isAvailable, cardSize, cardX + cardOffset, cardY + cardOffset);
              if (isAvailable) {
                clickZones.push({
                  top: cardY + cardOffset,
                  bottom: cardY + cardOffset + cardSize,
                  left: cardX + cardOffset,
                  right: cardX + cardOffset + cardSize,
                  action: (): void => this._selectShopPlant(plant, plantPos),
                });
              }
            }
          } else if (y === 0 && x === 4) {
            // power plant deck is in the top right
            if (this._deck.powerPlantDeckCount) {
              drawDeck(target, this._deck.nextPowerPlantDark ? DeckType.PlantDark : DeckType.PlantLight, this._deck.powerPlantDeckCount, cardSize, cardX + cardOffset, cardY + cardOffset);
            }
          } else if (y === 2 && x > 0) {
            // resource costs are in row 2
            const cost = x;
            const fontSize = cardSize * 0.3;

            target.context.fillStyle = "#FFFFFF";
            target.context.font = fontSize.toString() + "px Arial";
            target.context.textBaseline = "top";
            target.context.textAlign = "center";
            target.context.fillText(`€${cost}`, cardX + cardOffset + cardSize / 2, cardY + cardSize);
          } else if (y > 2 && x > 0) {
            // resources are in rows 3 and below in columns 2-5
            const price = x;
            const slot = y - 3;
            const fuel = this._store.fuel[price - 1][slot];
            if (fuel) {
              const isSelected = this._isFuelSelected(price, slot);
              drawFuel(target, fuel, isSelected, cardSize, cardX + cardOffset, cardY + cardOffset);
              clickZones.push({
                top: cardY + cardOffset,
                bottom: cardY + cardOffset + cardSize,
                left: cardX + cardOffset,
                right: cardX + cardOffset + cardSize,
                action: (): void => this._selectFuel(price, slot),
              });
            }
          } else if (y === 3 && x === 0) {
            // resource deck is in row 3 on the left
            drawDeck(target, DeckType.Resource, this._deck.resourceDeckCount, cardSize, cardX + cardOffset, cardY + cardOffset);
          } else if (y === 4 && x === 0) {
            // resource + deck is in row 4 on the left
            if (!this._deck.resourcePlusUsed) {
              drawDeck(target, DeckType.ResourcePlus, 4, cardSize, cardX + cardOffset, cardY + cardOffset);
            }
          } else if (y === 5 && x === 0) {
            // resource ++ deck is in row 5 on the left
            if (!this._deck.resourcePlusPlusUsed) {
              drawDeck(target, DeckType.ResourcePlusPlus, 4, cardSize, cardX + cardOffset, cardY + cardOffset);
            }
          }
        }
      }
    }
    return [cardsWidth, cardsHeight, clickZones];
  }
  drawPlayers(target: RenderTarget, twoPlayerColumns: boolean, top: number, left: number, spacer: number, width: number, height: number, cardsWidth: number, cardsHeight: number): Array<ClickZone> {
    // Players
    const clickZones: Array<ClickZone> = [];
    if (twoPlayerColumns) {
      const playerOriginX = left;
      const playerOriginY = top;
      const playerWidth = (width - cardsWidth - spacer * 2) / 2;
      const playerHeight = (cardsHeight - spacer * 2) / 3;
      const playerGapX = playerWidth + spacer + cardsWidth + spacer;
      const playerGapY = playerHeight + spacer;
      outer: for (let y = 0; y < 3; y++) {
        for (let x = 0; x < 2; x++) {
          if ((x + y * 2) >= this._players.length) {
            break outer;
          }
          const posX = playerOriginX + x * playerGapX;
          const posY = playerOriginY + y * playerGapY;

          const player = this._players[x + y * 2];
          const isSelf = player.uuid === this._self;
          const extraPlayerText = this._getExtraPlayerText(player);
          clickZones.push(...drawPlayer(target, player, this._playerHuesByUuid[player.uuid], isSelf, extraPlayerText, isSelf ? this._selectPlayerPlant : undefined, isSelf ? this._isPlayerPlantSelected : undefined, posX, posY, playerWidth, playerHeight));
        }
      }
    } else {
      const playerOriginX = left + cardsWidth + spacer;
      const playerOriginY = top;
      const playerWidth = width - cardsWidth - spacer;
      const playerHeight = (height - spacer * 5) / 6;
      const playerGapY = playerHeight + spacer;
      for (let y = 0; y < this._players.length; y++) {
        const posX = playerOriginX;
        const posY = playerOriginY + y * playerGapY;

        const player = this._players[y];
        const isSelf = player.uuid === this._self;
        const extraPlayerText = this._getExtraPlayerText(player);
        clickZones.push(...drawPlayer(target, player, this._playerHuesByUuid[player.uuid], isSelf, extraPlayerText, isSelf ? this._selectPlayerPlant : undefined, isSelf ? this._isPlayerPlantSelected : undefined, posX, posY, playerWidth, playerHeight));
      }
    }

    return clickZones;
  }
  processMessage(message: ServerMessage): void {
    switch (message.type) {
      case "game-state":
        this._store.fuel = message.fuelStore;
        this._store.plant = message.plantStore;
        this._store.plantDiscountAvailable = message.plantDiscountAvailable;
        this._deck = message.deckInformation;
        this._updatePlayers(message.players);
        this._playerOrder = message.playerOrdering;
        this._updateNextAction(message.nextAction);
        break;
      case "bid-changed":
        break;
      case "bid-done":
        break;
      case "move-power-plant":
        break;
      case "move-fuel":
        break;
      case "change-money":
        break;
      case "power-plant-used":
        break;
      case "spend-fuel":
        break;
      case "update-player-ordering":
        this._playerOrder = message.playerOrdering;
        break;
      case "need-user-action":
        this._updateNextAction(message.userAction);
        break;
      case "pong":
        break;
      case "error":
        this._showErrorMessage(message.message, message.joinError);
        break;
      default:
        console.error(`ERROR: Message type ${(message as any).type} doesn't exist.`);
    }
  }
  private static _copyUrlAction(): void {
    const dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.value = window.location.href;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }
  private _counterBidAction(currentBid: number): void {
    const money = this._getMoney();
    if (money < currentBid + 1) {
      console.debug("Ignoring counter-bid action due to insufficient funds.");
      return;
    }
    this._amountSelection = new AmountSelection("Please select a counter-bid amount:", currentBid + 1, money, (amount: number): void => {
      this._socket.send({
        type: "counter-bid",
        bidAmount: amount,
      });
    }, (): void => {
      this._amountSelection = undefined;
    });
  }
  private _isFuelSelected(price: number, slot: number): boolean {
    return !!this._selectedFuel
      && this._selectedFuel.findIndex((f: { price: number; slot: number; }): boolean => f.price === price && f.slot === slot) >= 0;
  }
  private _isPlayerPlantSelected: (playerPlantLocation: number) => boolean = (playerPlantLocation: number): boolean => {
    return !!this._selectedPlayerPlants
      && this._selectedPlayerPlants.findIndex((location: number): boolean => location === playerPlantLocation) >= 0;
  }
  private _getExtraPlayerText(player: PlayerState): string | undefined {
    if (!this._action) {
      return undefined;
    }
    switch (this._action.actionType) {
      case "bid-power-plant":
        if (this._action.player === player.uuid) {
          return "Bidding";
        } else if (this._action.doneBidding.indexOf(player.uuid) !== -1) {
          return "Done Bidding";
        }
        break;
      case "counter-bid":
        if (this._action.player === player.uuid) {
          return "Counter-bidding";
        } else if (this._action.doneBidding.indexOf(player.uuid) !== -1) {
          return "Done Bidding";
        } else if (this._action.alreadySkipped.indexOf(player.uuid) !== -1) {
          return "Skipped";
        }
        break;
      case "scrap-power-plant":
        if (this._action.player === player.uuid) {
          return "Scrapping";
        } else if (this._action.doneBidding.indexOf(player.uuid) !== -1) {
          return "Done Bidding";
        }
        break;
      case "buy-fuel":
        if (this._action.player === player.uuid) {
          return "Buying Fuel";
        }
        break;
      case "power-up":
        if (this._action.player === player.uuid) {
          return "Powering Up";
        }
        break;
      case "game-over":
        if (this._action.winner === player.uuid) {
          return "Winner";
        }
        break;
    }
    if (this._action.actionType === "counter-bid" && this._action.currentWinner === player.uuid) {
      return `High bidder at €${this._action.currentBid}`;
    }
    return undefined;
  }
  private _getIsPlantActive(plant: PlantCard, plantLocation: number): boolean {
    return (this._action && this._action.actionType === "counter-bid" && this._action.location === plantLocation)
      || (this._action && this._action.actionType === "scrap-power-plant" && this._action.newPlantLocation === plantLocation)
      || (this._selectedPlant && this._selectedPlant === plantLocation)
      || false;
  }
  private _getMoney(): number {
    const me = this._playersByUuid[this._self];
    return me?.money ?? 0;
  }
  private static _getActionWithPlayer(action?: PlayerAction): PlayerActionWithPlayer | undefined {
    if (!action) {
      return undefined;
    }
    switch (action.actionType) {
      case "bid-power-plant":
      case "counter-bid":
      case "scrap-power-plant":
      case "buy-fuel":
      case "power-up":
        return action;
    }
    return undefined;
  }
  private static _newGameAction(): void {
    window.location.assign(window.location.href.replace(window.location.search, ""));
  }
  private _selectFuel(price: number, slot: number): void {
    if (this._action
      && this._action.actionType === "buy-fuel"
      && this._action.player === this._self) {

      if (!this._selectedFuel) {
        this._confirmation = new Confirmation("Please select all fuel to purchase.", "Purchase Fuel", true, (): boolean => {
          if (this._selectedFuel && this._selectedFuel.length > 0) {
            this._socket.send({
              type: "buy-fuel",
              fuel: this._selectedFuel
                .map((f: { price: number; slot: number; }): ShopFuelPosition => ({
                  position: "shop",
                  price: f.price,
                  slot: f.slot,
                })),
            });
            return true;
          }
          return false;
        }, (): void => {
          this._confirmation = undefined;
          this._selectedFuel = undefined;
        });
        this._selectedFuel = [{price, slot}];
      } else {
        if (this._isFuelSelected(price, slot)) {
          this._selectedFuel = this._selectedFuel.filter((f: { price: number; slot: number; }): boolean => f.price !== price || f.slot !== slot);
        } else {
          this._selectedFuel.push({price, slot});
        }
      }
    } else {
      console.debug("Ignoring fuel click when it isn't our buy fuel phase.");
    }
  }
  private _selectPlantsToPowerUp(): void {
    if (!this._selectedPlayerPlants) {
      this._confirmation = new Confirmation("Please select plants to power up.", "Power Up", false, (): boolean => {
        if (this._selectedPlayerPlants) {
          const player = this._playersByUuid[this._self];
          const fuelAmounts: FuelAmounts = {
            coalAmount: 0,
            oilAmount: 0,
            gasAmount: 0,
            nuclearAmount: 0,
          };
          let hybridAmount = 0;
          for (const location of this._selectedPlayerPlants) {
            const plant = player.plants[location];
            switch (plant.plantType) {
              case "coal":
                fuelAmounts.coalAmount += plant.resourcesNeeded;
                break;
              case "free":
                break;
              case "gas":
                fuelAmounts.gasAmount += plant.resourcesNeeded;
                break;
              case "hybrid":
                hybridAmount += plant.resourcesNeeded;
                break;
              case "nuclear":
                fuelAmounts.nuclearAmount += plant.resourcesNeeded;
                break;
              case "oil":
                fuelAmounts.oilAmount += plant.resourcesNeeded;
                break;
            }
          }
          if (player.resources.coalAmount < fuelAmounts.coalAmount
            || player.resources.gasAmount < fuelAmounts.gasAmount
            || player.resources.nuclearAmount < fuelAmounts.nuclearAmount
            || player.resources.oilAmount < fuelAmounts.oilAmount
          ) {
            this._showErrorMessage("You do not have enough fuel to power those plants.");
            return false;
          }
          if (hybridAmount > 0) {
            const remainingOil = player.resources.oilAmount - fuelAmounts.oilAmount;
            const remainingGas = player.resources.gasAmount - fuelAmounts.gasAmount;

            if (hybridAmount > remainingGas + remainingOil) {
              this._showErrorMessage("You do not have enough fuel to power those plants.");
              return false;
            }
            const minimumGasToUse = Math.max(0, hybridAmount - remainingOil);
            const maximumGasToUse = Math.min(hybridAmount, remainingGas);
            if (minimumGasToUse === maximumGasToUse) {
              fuelAmounts.gasAmount += minimumGasToUse;
              fuelAmounts.oilAmount += hybridAmount - minimumGasToUse;
            } else {
              const localSelectedPlayerPlants = this._selectedPlayerPlants;
              this._amountSelection = new AmountSelection("Please select how much gas to use:", minimumGasToUse, maximumGasToUse, (amount: number): void => {
                fuelAmounts.gasAmount += amount;
                fuelAmounts.oilAmount += hybridAmount - amount;
                  this._socket.send({
                  type: "power-up",
                  fuelToUse: fuelAmounts,
                  plantsToUse: localSelectedPlayerPlants,
                });
              }, (): void => {
                this._amountSelection = undefined;
                this._selectedPlayerPlants = undefined;
              });
              this._confirmation = undefined;
              return false;
            }
          }
          this._socket.send({
            type: "power-up",
            fuelToUse: fuelAmounts,
            plantsToUse: this._selectedPlayerPlants,
          });
          return true;
        }
        return false;
      }, (): void => {
        this._confirmation = undefined;
        this._selectedPlayerPlants = undefined;
      });
      const me = this._playersByUuid[this._self];
      this._selectedPlayerPlants = me.plants.map((_: unknown, index: number): number => index);
    }
  }
  private _selectPlayerPlant: (playerPlantLocation: number) => void = (playerPlantLocation: number): void => {
    if (this._action
      && this._action.actionType === "power-up"
      && this._action.player === this._self) {

      if (!this._selectedPlayerPlants) {
        this._selectPlantsToPowerUp();
      } else {
        if (this._isPlayerPlantSelected(playerPlantLocation)) {
          this._selectedPlayerPlants = this._selectedPlayerPlants.filter((location: number): boolean => location !== playerPlantLocation);
        } else {
          this._selectedPlayerPlants.push(playerPlantLocation);
        }
      }
    } else if (this._action
      && this._action.actionType === "scrap-power-plant"
      && this._action.player === this._self) {

      if (!this._selectedPlayerPlants) {
        this._confirmation = new Confirmation("Are you sure you want to scrap that plant?", "Scrap Plant", true, (): boolean => {
          if (this._selectedPlayerPlants && this._selectedPlayerPlants.length > 0) {
            this._socket.send({
              type: "scrap-power-plant",
              location: this._selectedPlayerPlants[0],
            });
            return true;
          }
          return false;
        }, (): void => {
          this._confirmation = undefined;
          this._selectedPlayerPlants = undefined;
        });
      }
      this._selectedPlayerPlants = [playerPlantLocation];
    } else {
      console.debug("Ignoring plant click when it isn't our power up or scrap phase.");
    }
  }
  private _selectShopPlant(plant: PlantCard, plantPos: number): void {
    if (this._action
      && this._action.actionType === "bid-power-plant"
      && this._action.player === this._self) {

      const isDiscounted = plantPos === 0 && this._store.plantDiscountAvailable;
      const minimumCost = isDiscounted ? 1 : plant.cost;
      const money = this._getMoney();
      if (minimumCost > money) {
        this._showErrorMessage(`That plant is €${minimumCost}, you can't afford it.`);
      } else {
        this._amountSelection = new AmountSelection("Please select a bid amount:", minimumCost, money, (amount: number): void => {
          this._socket.send({
            type: "bid-power-plant",
            location: plantPos,
            startingBid: amount,
          });
        }, (): void => {
          this._amountSelection = undefined;
          this._selectedPlant = undefined;
        });
        this._selectedPlant = plantPos;
      }
    } else {
      console.debug("Ignoring plant click when it isn't our bid phase.");
    }
  }
  private _showErrorMessage(errorMessage: string, joinError?: boolean): void {
    this._errorMessage = errorMessage;
    this._errorIsJoinError = joinError;
    boop();
  }
  private _skipBiddingAction: () => void = () => {
    this._socket.send({
      type: "bid-power-plant",
      location: 0,
      startingBid: 0,
    });
  }
  private _skipCounterBiddingAction: () => void = () => {
    this._socket.send({
      type: "counter-bid",
      bidAmount: 0,
    });
  }
  private _skipFuelAction: () => void = () => {
    this._socket.send({
      type: "buy-fuel",
      fuel: [],
    });
  }
  private _skipPowerUpAction: () => void = () => {
    this._socket.send({
      type: "power-up",
      fuelToUse: {
        coalAmount: 0,
        oilAmount: 0,
        gasAmount: 0,
        nuclearAmount: 0,
      },
      plantsToUse: [],
    });
  }
  private _startGameAction: () => void = () => {
    this._socket.send({
      type: "start-game",
    });
  }
  private _updateNextAction(nextAction: PlayerAction): void {
    // make a sound if it is your turn to perform an action
    const oldActionWithPlayer = Game._getActionWithPlayer(this._action);
    const newActionWithPlayer = Game._getActionWithPlayer(nextAction);
    if (newActionWithPlayer && newActionWithPlayer.player === this._self
        && (!oldActionWithPlayer || oldActionWithPlayer.player !== this._self || oldActionWithPlayer.actionType !== newActionWithPlayer.actionType)) {
      ping();
    }

    // make a sound if the game is over
    if ((!this._action || this._action.actionType !== "game-over") && nextAction && nextAction.actionType === "game-over") {
      tada();
    }

    this._action = nextAction;

    // initially select all power plants to power up if it is our turn to power up
    if (this._action && this._action.actionType === "power-up" && this._action.player === this._self) {
      this._selectPlantsToPowerUp();
    }

    // give a notification if this is the final round
    if (this._action && this._action.actionType === "bid-power-plant" && this._action.lateGame && !this._seenLateGame) {
      this._showErrorMessage("This is the final round.");
      this._seenLateGame = true;
    }
  }
  private _updatePlayers(players: Array<PlayerState>): void {
    this._players = players;
    this._playersByUuid = {};
    this._playerHuesByUuid = {};
    const hueShift = 360 / this._players.length;
    for (let i = 0; i < players.length; i += 1) {
      const player = players[i];
      this._playersByUuid[player.uuid] = player;
      this._playerHuesByUuid[player.uuid] = hueShift * i;
    }
  }
  private _amountSelection: AmountSelection | undefined;
  private _action?: PlayerAction;
  private _confirmation: Confirmation | undefined;
  private _deck: DeckInformation;
  private _errorMessage?: string;
  private _errorIsJoinError?: boolean;
  private _playerOrder: Array<Player>;
  private _players: Array<PlayerState>;
  private _playerHuesByUuid: Record<string, number>;
  private _playersByUuid: Record<string, PlayerState>;
  private _seenLateGame: boolean = false;
  private _self: Player;
  private _selectedFuel: Array<{ price: number; slot: number; }> | undefined;
  private _selectedPlant: number | undefined;
  private _selectedPlayerPlants: Array<number> | undefined;
  private _socket: Connection;
  private _store: {
    fuel: FuelStore;
    plant: Array<PlantCard>;
    plantDiscountAvailable: boolean;
  };
}
